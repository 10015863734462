import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import UserBalance from 'UI/UserBalance'
import './Tasks.css'
import getTasks from 'api/get-tasks'
import Task from 'types/task'
import appContext from 'utils/app-context'
import TaskCard from './components/TaskCard'
type Props = {}

const Tasks = (props: Props) => {
  const [tasks, setTasks] = useState<Task[] | null>(null)
  const [category, setcategory] = useState(0)
    const updateTasks = async () => {
        const result = await getTasks()
        if(result) setTasks(result)
    }
    useEffect(() => {
        updateTasks()
    }, [])

    const unfinished = tasks?.filter(t => t.status !== 1)
    const finished = tasks?.filter(t => t.status === 1)
  return (
    <PageWrapper className='tasks-page'>
      <UserBalance />
      <h1>Tasks</h1>
      <div className='tasks-category-select'>
        <button onClick={() => setcategory(0)} className={category === 0 ? `active` : ''}>Active</button>
        <button onClick={() => setcategory(1)} className={category === 1 ? `active` : ''}>Done</button>
      </div>
      <ul className='tasks-list'>
        {(category === 0 ? unfinished : finished)?.map((t) => <TaskCard task={t} key={t.id} updateTasks={updateTasks}/>)}
      </ul>
    </PageWrapper>
  )
}

export default Tasks