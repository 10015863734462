import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import React from 'react'
import Icon from 'UI/Icon';
import tg from 'utils/tg';

type Props = {}

const TonWallet = (props: Props) => {
  const [tonConnectUI, setOptions] = useTonConnectUI();
  
  const userFriendlyAddress = useTonAddress();
  const isWalletConnected = tonConnectUI.connected;
  const connectWallet = () => {
   try {
    if (isWalletConnected) {
      const d = () => tonConnectUI.disconnect();
      tg.showConfirm('You are sure of turning off the wallet?',(ok) => {
        if(ok) d()
      })
    } else tonConnectUI.openModal();
   } catch (error) {
    console.log("🚀 ~ connectWal ~ error:", error)
    
   }
  };
  return (
    <button className='ton-wallet' onClick={connectWallet}>
      <Icon icon='wallet'/>
      {isWalletConnected && <p>{collapseAddress(userFriendlyAddress)}</p>}
      <Icon icon={isWalletConnected ? 'delete' : 'add'}/>
    </button>
  )
}
const collapseAddress = (address:string) => `${address.slice(0,2)}...${address.slice(-4)}` 

export default TonWallet