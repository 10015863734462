import Friends from "pages/Friends/Friends";
import Home from "pages/Home/Home";
import Loading from "pages/Loading/Loading";
import Rating from "pages/Rating/Rating";
import Tasks from "pages/Tasks/Tasks";
import Wallet from "pages/Wallet/Wallet";
import { RouteObject } from "react-router-dom";
import Icon from "UI/Icon";

type Route = {
  route: RouteObject;
  titleKey?: string;
  navBarIcon?: JSX.Element;
  border?:string
};

const routes: Route[] = [
  {
    route: {
      path: "/",
      element: <Loading />,
    },
  },
  {
    route: {
      path: "/rating",
      element: <Rating />,
    },
    titleKey: "Rating",
    navBarIcon:<Icon icon="navigationRating"/>
  },
  {
    route: {
      path: "/tasks",
      element: <Tasks />,
    },
    titleKey: "Tasks",
    navBarIcon:<Icon icon="navigationTasks"/>
  },
  {
    route: {
      path: "/home",
      element: <Home />,
    },
    titleKey: "Trade",
    border:'#00BFBF',
    navBarIcon:<Icon icon="navigationTrade"/>
  },
  {
    route: {
      path: "/wallet",
      element: <Wallet />,
    },
    titleKey: "Wallet",
    navBarIcon:<Icon icon="navigationWallet"/>
  },
  {
    route: {
      path: "/friends",
      element: <Friends />,
    },
    titleKey: "Friends",
    navBarIcon:<Icon icon="navigationFriends"/>
  },
  
];
export default routes;
