import {
  RouterProvider,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import routes from "./routes";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import tg from "utils/tg";
import LoadingScreen from "UI/LoadingScreen";
import Navigation from "UI/Navigation";
import AppContext from "utils/app-context";
import useAppData from "hooks/use-app-data";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
function App() {
  const navigation = useNavigate();
  const { AppContextValue } = useAppData();
  const element = useRoutes(routes.map(({ route }) => route));
  const location = useLocation();

  const reloadBackButton = () => {
    if (tg.BackButton.isVisible) return false;
    tg.BackButton.onClick(() => {
      navigation(-1);
    });
    tg.BackButton.show();
  };

  useEffect(() => {
    tg.expand();
    tg.disableVerticalSwipes();
    reloadBackButton();
    tg.onEvent("viewportChanged", reloadBackButton);

    // const isFirstLaunch = localStorage.getItem('isFirstLaunch')
    // if(isFirstLaunch && location.pathname === '/') navigation('/tap')
  }, []);
  if (!element) return null;
  return (
    <TonConnectUIProvider
      manifestUrl={window.location.origin + "/tonconnect-manifest.json"}
    >
      <AppContext.Provider value={AppContextValue}>
        <AnimatePresence mode="sync">
          {React.cloneElement(element, { key: location.pathname })}
        </AnimatePresence>
        <Navigation />
        <LoadingScreen />
      </AppContext.Provider>
    </TonConnectUIProvider>
  );
}

export default App;
