import { CandleData } from "types/candle";

const generateTestData = (count: number):CandleData[] => {
  const data = [];
  let lastClose = Math.random() * 100 + 50; // Начальное значение цены

  for (let i = 0; i < count; i++) {
    const newItem = getNextItem(lastClose)
    data.unshift(newItem);

    lastClose = newItem.close;
  }

  return data;
};


  export const getNextItem = (lastClose:number):CandleData => {
    const open = lastClose;
    const high = open + Math.random() * 10;
    let low = open - Math.random() * 10;
    if (low < 0) low = 0; // Гарантируем, что low не меньше 0
    const close = Math.random() * (high - low) + low;
    return {
      open: parseFloat(open.toFixed(2)),
      high: parseFloat(high.toFixed(2)),
      low: parseFloat(low.toFixed(2)),
      close: parseFloat(close.toFixed(2)),
      id:crypto.randomUUID()
    }
  } 

  export default generateTestData