import React, { useContext } from 'react'
import PageWrapper from 'UI/PageWrapper'
import UserBalance from 'UI/UserBalance'
import './Friends.css'
import appContext from 'utils/app-context'
import formatNumber from 'utils/format-number'
import Icon from 'UI/Icon'
import { motion } from 'framer-motion'
import tg from 'utils/tg'
type Props = {}

const tg_id = tg.initDataUnsafe.user?.id;
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''
const shareText = process.env.REACT_APP_SHARE_TEXT || '';


const Friends = (props: Props) => {
  const {user} = useContext(appContext)
  const copyShareLink = (e:React.MouseEvent) => {
    tg.HapticFeedback.notificationOccurred('success')
    navigator.clipboard.writeText(inviteLink)
  }
  const TelegramShare = () => {
      tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${(shareText.replace(/\\n/g, '%0A'))}`)
  }
  return (
    <>
    <PageWrapper className='friends-page'>
      <UserBalance/>
      <h1>Friends</h1>
      <div className="friends-info-blocks">
        <div className="friends-info-block">
          <h2>Invite Friends from Telegram</h2>
          <div>
            <img src="img/coin.png" alt="" className='coin'/>
            <span>+12</span>
          </div>
        </div>
        <div className="friends-info-block premuim">
          <h2>Invite Friends with <b>Telegram Premium</b></h2>
          <div>
            <img src="img/black-coin.png" alt=""className='coin' />
            <span>+20</span>
          </div>
        </div>
      </div>
      <ul className='friends-list'>
        {user?.friends_list.map(({coins,name,photo}) => {
          return (
            <li>
              <div className='friends-profile'>
                <img src={photo} alt="" className='friend-photo'/>
                <span>{name}</span>
              </div>
              <div>
              <img src="img/coin.png" alt="" className='coin'/>
                <span>{formatNumber(coins)}</span>
              </div>
            </li>
          )
        })}
      </ul>
    </PageWrapper>
    <motion.div
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    className='freinds-buttons'>
      <button onClick={TelegramShare}>Invite Friends</button>
      <button onClick={copyShareLink} className='copy'><Icon icon='copy'/></button>
    </motion.div>
    </>
  )
}

export default Friends