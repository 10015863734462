import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import generateTestData, { getNextItem } from "utils/generate-data";
import AnimatedNumbers from "react-animate-numbers";
import Icon from "./Icon";
import { CandleData } from "types/candle";

type Props = {
  fixValue:boolean
  handleChanges: (data: CandleData[]) => void;
};
const CANDLE_WIDTH = 14;
const CANDLE_GAP = 4.5; // Отступ между свечами
const startData = generateTestData(20)

const CandleChart = ({ handleChanges ,fixValue}: Props) => {
  const [data, setData] = useState<null | typeof startData>(null);
  const [chartSize, setChartSize] = useState({w:0,h:0});
  const [bidStart, setBidStart] = useState<number | null>(null);
  const chart = useRef<HTMLDivElement>(null);
  const [last, setlast] = useState(0);

  // Вычисление максимума и минимума
  let max = 0
  let min = 0
  if(data) {
    max = Math.max(...data.map((item) => item.high));
    min = Math.min(...data.map((item) => item.low));
  }
  const scaleY = (value: number) => {
    // console.log(`🚀 ~ scaleY: ((${value} - ${min}) / (${max} - ${min})) * ${chartSize.h}`)
    // Приводим значения к размеру графика
    const res = ((value - min) / (max - min)) * chartSize.h
    return res;
  };

  const [int, dec] = String(last).split(".");
  const checkBid = (nextValue:number) => {
  }
  useEffect(() => {
    const {h,w} = chartSize
    if((!h || !w) && chart.current) {
      setTimeout(() => {
        if(!chart.current) return
        const { height: h, width: w } = chart.current.getBoundingClientRect();
        setChartSize({ w, h:h - 40});
        setData(startData)
        setlast(startData[0].close)
      }, 100);
    }
    if(!data) return
    setTimeout(() => {
      const next = getNextItem(data[0].close);
      const newData = [...data];
      newData.pop();
      newData.unshift(next);
      setData(newData);
      setlast(next.close);
      checkBid(next.close)
      handleChanges(newData)
    }, 2000);
  }, [data]);

  useEffect(() => {
    if(fixValue) setBidStart(last)
    else setBidStart(null)
  }, [fixValue])

  return (
    <div className="candle-chart-container">
      <img src="img/tv.png" alt="" />
      <div className="candle-chart-data">
        {data && <div
          className={`candle-chart-value ${data[1].close > data[0].close ? "red" : 'green'}`}>
          <AnimatedNumbers
            value={Number(int)}
            maxLength={3}
            options={{
              animationSpeed: "lg",
              animationType: "ease-in-out",
            }}
          />
          .
          <AnimatedNumbers
            value={Number(dec)}
            maxLength={2}
            options={{
              animationSpeed: "lg",
              animationType: "ease-in-out",
            }}
          />
          <motion.div
          className="arrow-value"
          animate={{rotate:data[1].close < data[0].close ? 180 : 0}}
          >
          <Icon icon="arrow"/>
          </motion.div>
        </div>}
        <div ref={chart} className="candle-chart">
          
          {(!!chartSize.h && !!chartSize.w) && data?.map(({ close, high, low, open, id }, index) => {
            const isBullish = close > open; // Зеленая или красная свеча
            
            const bodyTop = scaleY(Math.max(close, open));
            const bodyBottom = scaleY(Math.min(close, open));
            const lineTop = scaleY(high);
            const lineBottom = scaleY(low);
            return (
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                key={id}
                className="candle"
                style={{
                  position: "absolute",
                  right: index * (CANDLE_WIDTH + CANDLE_GAP),
                  width: CANDLE_WIDTH,
                  top: 0, // Привязываемся к нижнему краю
                }}
              >
                <motion.div
                  layout
                  initial={{ height: 0 }}
                  animate={{ height: lineTop - lineBottom }}
                  className="candle-line"
                  style={{
                    position: "absolute",
                    left: CANDLE_WIDTH / 2 - 1, // Центрируем линию
                    top: chartSize.h - lineTop,
                    height: lineTop - lineBottom,
                    width: 2,
                    background: isBullish ? "#2FCA0C" : "#C20E0F",
                  }}
                />
                <motion.div
                  layout
                  initial={{ height: 0 }}
                  animate={{ height: bodyTop - bodyBottom }}
                  className="candle-body"
                  style={{
                    position: "absolute",
                    top: chartSize.h - bodyTop,
                    height: bodyTop - bodyBottom,
                    width: CANDLE_WIDTH,
                    background: isBullish ? "#2FCA0C" : "#C20E0F",
                  }}
                />
              </motion.div>
            );
          })}
          {bidStart && (
            <motion.div
              className="bidStart"
              animate={{
                top: chartSize.h - scaleY(bidStart),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CandleChart;
