import doneTask from 'api/done-task'
import React, { useContext, useState } from 'react'
import Task from 'types/task'
import appContext from 'utils/app-context'
import tg from 'utils/tg'

type Props = {
    task:Task
    updateTasks:() => void
}

const TaskCard = ({task:{icon,id,title,status,url,reward},updateTasks}: Props) => {
    const [pending, setpending] = useState(false)
  const {setUser,user} = useContext(appContext)

  const HandleTask = async () => {
    setpending(false)
      const result = await doneTask(id)
      const err = () => tg.showAlert('A mistake arose')
      switch (result?.status) {
          case 2:
            // if (!url) toast(text.taskNotDone,{autoClose:1000,hideProgressBar:true,type:'info'})
          break;
      }
      if(result?.balance && user) setUser({...user,balance:result?.balance})
      if(!result) err()
      updateTasks()
  } 

  const claimTask = (open:boolean) => {
    if(status === 1) return
    setpending(true)
    if (url && open) {
      try {
        tg.openTelegramLink(url)
      } catch (error) {
        tg.openLink(url)
      }
    }
    

    setTimeout(HandleTask,3000)
  }

  return (
    <li className='task-card'>
          <div>
            <img src={`./img/icons/${icon}`} alt="" />
            <div>
              <h3>{title}</h3>
              <div className='task-reward'>
                <img className='coin' src="img/coin.png" alt="" />
                  + {reward}
              </div>
            </div>
          </div>
          <button onClick={() => claimTask(true)}>GO</button>
        </li>
  )
}

export default TaskCard