import React, { useState } from "react";
import PageWrapper from "UI/PageWrapper";
import UserBalance from "UI/UserBalance";
import TonWallet from "./components/TonWallet";
import "./Wallet.css";
type Props = {};

const Wallet = (props: Props) => {
  const [category, setcategory] = useState(0);
  return (
    <PageWrapper className="wallet-page">
      <UserBalance />
      <div className="wallet-page-header">
        <h1>Wallet</h1>
        <TonWallet />
      </div>
      <div className="tasks-category-select">
        <button
          onClick={() => setcategory(0)}
          className={category === 0 ? `active` : ""}
        >
          Credit
        </button>
        <button
          onClick={() => setcategory(1)}
          className={category === 1 ? `active` : ""}
        >
          Debit
        </button>
      </div>
    </PageWrapper>
  );
};

export default Wallet;
