import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import appContext from 'utils/app-context'

type Props = {
  balanceScale?:number
}

const UserBalance = ({balanceScale}: Props) => {
  console.log("🚀 ~ UserBalance ~ balanceScale:", balanceScale)
  const {user} = useContext(appContext)
  return (
    <div className="user-bar">
    <div className="user">
      <img src={user?.avatar} alt="" />
      <span>{user?.first_name}</span>
    </div>

    <motion.div className="balance" animate={{scale:balanceScale}} transition={{duration:0}}>
      <img src="img/coin.png" alt="" id='user-balance-coin'/>
      <span>{user?.balance}</span>
    </motion.div>
  </div>
  )
}

export default UserBalance