import { useEffect, useState } from "react"
import AppContextType from "types/app-context";
import getUserData from "api/get-user-data";
import UserData from "types/user-data";
import tg from "utils/tg";
const errors = {
    "appError":"It seems that some kind of error has occurred, you need to go back to the bot and send /start",
    "quit":"Back to the bot"
}
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(1)) || ""
export default () => {
    const [user, setUser] = useState<UserData | null>(null)

    const updateUserInfo = async () => {
        const result = await getUserData()
        if (result){
            // const correction:UserData = {
            //     ...result,
            //     roulette_cond_friends:0,
            //     roulette_cond_sub:0,
            // }
            // setUser(correction)
            setUser(result)
        }
        if(result?.error) {
            tg.showPopup({message:errors.appError,buttons:[{type:'close',text:errors.quit}]},() => {
                tg.close()
                tg.openTelegramLink(inviteLink)
            })
        }
    }

  



    const AppContextValue:AppContextType = {
        user,
        updateUserInfo,
        setUser,
    }


    useEffect(() => {
        updateUserInfo()
    }, [])
   
    return {AppContextValue}
}