import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    icon:keyof typeof icons
    size?:number
}

const Icon = (props: Props) => {
    const icon = icons[props.icon]
    return icon(props)
}

const icons = {
    arrow:(props:Props) => (
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5 30.5L18.5 6.5" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.0003 23.0001C11.0003 23.0001 16.5239 30.5 18.5003 30.5C20.4767 30.5 26.0003 23 26.0003 23" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    navigationRating:(props:Props) => (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M12 17c-1.674 0-3.13 1.265-3.882 3.131-.36.892.156 1.869.84 1.869h6.083c.685 0 1.2-.977.841-1.869C15.13 18.265 13.674 17 12 17Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.5 5h1.202c1.201 0 1.801 0 2.115.377.313.378.183.944-.078 2.077l-.39 1.7C20.76 11.708 18.61 13.608 16 14M5.5 5H4.298c-1.201 0-1.802 0-2.115.377-.313.378-.183.944.078 2.077l.39 1.7C3.24 11.708 5.39 13.608 8 14"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M12 17c3.02 0 5.565-4.662 6.33-11.01.211-1.754.317-2.632-.243-3.311C17.527 2 16.622 2 14.813 2H9.187c-1.81 0-2.714 0-3.274.679S5.46 4.236 5.67 5.991C6.435 12.338 8.98 17 12 17Z"
    />
  </svg>
    ),
    navigationTasks:(props:Props) => (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.5 13V9.368c0-3.473 0-5.21-1.025-6.289C17.45 2 15.8 2 12.5 2h-3C6.2 2 4.55 2 3.525 3.08 2.5 4.157 2.5 5.894 2.5 9.367v5.264c0 3.473 0 5.21 1.025 6.289C4.55 22 6.2 22 9.5 22H11M13.5 20s1 0 2 2c0 0 3.177-5 6-6"
    />
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7 2 .082.493c.2 1.197.3 1.796.72 2.152C8.22 5 8.827 5 10.041 5h1.917c1.213 0 1.82 0 2.24-.355.42-.356.52-.955.719-2.152L15 2"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M7 16h4m-4-5h8"
    />
  </svg>
    ),
    navigationTrade:(props:Props) => (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M9 5v4M10 9H8a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1ZM9 15v2M17 3v2M18 5h-2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1ZM17 13v3"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M3 3v16a2 2 0 0 0 2 2h16"
        />
      </svg>
    ),
    navigationWallet:(props:Props) => (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeWidth={1.5}
      d="M14.5 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19 11.142a13.62 13.62 0 0 0-1-.107m-12 1.93c-.341-.024-.675-.06-1-.107"
    />
    <path
      stroke="#fff"
      strokeWidth={1.5}
      d="M12 19.5c-1.332.622-3.083 1-5 1-1.066 0-2.08-.117-3-.327-1.5-.344-2-1.247-2-2.787V6.614c0-.985 1.04-1.661 2-1.441.92.21 1.934.327 3 .327 1.917 0 3.668-.378 5-1 1.332-.622 3.083-1 5-1 1.066 0 2.08.117 3 .327 1.582.363 2 1.293 2 2.787v10.772c0 .985-1.04 1.661-2 1.441-.92-.21-1.934-.327-3-.327-1.917 0-3.668.378-5 1Z"
    />
  </svg>
    ),
    navigationFriends:(props:Props) => (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.5 22H6.59c-1.545 0-2.774-.752-3.877-1.803-2.26-2.153 1.45-3.873 2.865-4.715A10.657 10.657 0 0 1 13 14.188"
    />
    <path
      stroke="#fff"
      strokeWidth={1.5}
      d="M15.5 6.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m18.691 14.578.704 1.42a.87.87 0 0 0 .568.423l1.276.213c.816.137 1.008.734.42 1.323l-.992 1a.88.88 0 0 0-.208.73l.284 1.238c.224.98-.292 1.359-1.152.847l-1.196-.714a.858.858 0 0 0-.792 0l-1.196.714c-.856.512-1.376.129-1.152-.847l.284-1.238a.88.88 0 0 0-.208-.73l-.992-1c-.583-.589-.395-1.186.42-1.323l1.276-.213a.872.872 0 0 0 .564-.424l.704-1.42c.384-.77 1.008-.77 1.388 0Z"
    />
  </svg>
    ),
    wallet:(props:Props) => (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        stroke="#00BFBF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19 7V4a1 1 0 0 0-1-1H5a2 2 0 1 0 0 4h15a1 1 0 0 1 1 1v4m0 0h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z"
      />
      <path
        stroke="#00BFBF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4"
      />
    </svg>
    ),
    delete:(props:Props) => (
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#818181"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3 6h18M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2M10 11v6M14 11v6"
    />
  </svg>
    ),
    add:(props:Props) => (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5 12h14M12 5v14"
      />
    </svg>
    ),
    copy:(props:Props) => (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20 8H10a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"
      />
    </svg>
    )

    
}

export default Icon