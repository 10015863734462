import React, { useState, useRef, useEffect, useContext, ReactNode } from "react";
import PageWrapper from "UI/PageWrapper";
import "./Home.css";
import CandleChart from "UI/CandleChart";
import tg from "utils/tg";
import { CandleData } from "types/candle";
import appContext from "utils/app-context";
import UserBalance from "UI/UserBalance";
import { AnimatePresence, motion } from "framer-motion";

type BidType = number | "MAX";
const availableBids: BidType[] = [1, 5, 10, "MAX"];
type AnimatedCoin = {
  elem:ReactNode,
  id:string
}
const Home = () => {
  const { user, setUser } = useContext(appContext);
  const [indexBid, setIndexBid] = useState(0);
  const [playBid, setPlayBid] = useState<null | boolean>(null);
  const [ispressed, setispressed] = useState<boolean | "r" | "l">(false);
  const [animatedItems, setanimatedItems] = useState<AnimatedCoin[]>([])
  const [balanceScale, setbalanceScale] = useState(1)
  useEffect(() => {
    playBidRef.current = playBid;
  }, [playBid]);
  useEffect(() => {
    if(balanceScale > 1) {
      setTimeout(() => {
        setbalanceScale(prev => prev - 0.05)
      }, 50);
    }
  }, [balanceScale])
  const handleRewardAnimation = (reward:number) => {
    const perCoin = reward > 10 ? reward / 10 : 1
    const count = reward > 10 ? 10 : reward
    const startElem = document.querySelector('.candle-chart-container')
    if(!startElem) return
    const {left,top,height,width} = startElem.getBoundingClientRect()
    const startLeft = left + width / 2 - 12
    const startTop = top + height / 2 - 12
    for (let i = 1; i <= count; i++) {
      
      const id = String(Date.now() + 10 * i)

      const target = document.getElementById('user-balance-coin')
      if(!target) return
      const {left,top,height,width} = target.getBoundingClientRect()

      setTimeout(() => {
        setanimatedItems(prev => [...prev,{
          id:id,
          elem:(
            <motion.img src="img/coin.png"
            className="animated-coin"
              key={id}
              initial={{top:startTop,left:startLeft,opacity:0}}
              animate={{left,top,height,width,opacity:1}}
              exit={{opacity:0}}
              onAnimationComplete={() => {
                console.log('Complete!');
                
                setUser(prev => {
                  if(!prev) return prev
                  return {...prev,balance:prev.balance + perCoin}
                })
                setbalanceScale(prev => prev + 0.15)
                setanimatedItems(prev => prev.filter((i) => i.id !== id))
              }}
            />
          )
        }])
      }, 100 * i);
    }

  }
 

  const playBidRef = useRef<null | boolean>(playBid);
  const hero = ispressed ? "img/hero/1-state-press.png" : "img/hero/1.png"
  if (!user) return null;

  const bid: number =
    typeof availableBids[indexBid] === "string"
      ? user.balance
      : (availableBids[indexBid] as number);

  // Ref для хранения актуального значения playBid

  const handleChanges = (data: CandleData[]) => {
    const currentPlayBid = playBidRef.current;
    console.log("🚀 ~ handleChanges ~ playBid:", currentPlayBid);

    if (currentPlayBid === null) return;
    const { close, open } = data[0];

    const lost = () => {
      // try {
      //   tg.showAlert("You lost");
      // } catch (error) {
      //   console.log("You lost");
      // }
    };

    const win = () => {
      // const t = `You won ${(bid as number) * 3} coins!`
      // try {
      //   tg.showAlert(t);
      // } catch (error) {
      //   console.log(t);
      // }
      // setUser({ ...user, balance: user?.balance + bid * 3 });
      handleRewardAnimation(bid * 3)
    };

    if (close > open) {
      if (currentPlayBid) win();
      else lost();
    } else {
      if (!currentPlayBid) win();
      else lost();
    }

    setPlayBid(null);
  };

  const checkBid = () => {
    if (bid > user.balance) {
      tg.showAlert("You lack coins for the bet");
      return true;
    }
    return false;
  };

 
  return (
    <PageWrapper className="home-page">
      <UserBalance balanceScale={balanceScale}/>
      <CandleChart handleChanges={handleChanges} fixValue={playBid !== null} />
      <div className="actions">
        <AnimatePresence>
        <motion.img
          src={hero}
          alt=""
          key={String(hero + ispressed)}
          initial={{opacity:0}}
          animate={{opacity:1,transition:{duration:0.1}}}
          exit={{opacity:0,zIndex:1}}
          className="hero"
          style={ispressed === 'r' ? {
            transform:'translateX(-50%) scaleX(-1)'
          } : {}}
        />
        </AnimatePresence>
        <div className="btn-container">
          <button
            onPointerDown={() => setispressed("l")}
            onPointerUp={() => setispressed(false)}
            onClick={() => {
              if (checkBid()) return;
              setPlayBid(false);
              setUser({ ...user, balance: user.balance - bid });
            }}
            className="sell"
          >
            Sell
          </button>
          <button
            onPointerDown={() => setispressed("r")}
            onPointerUp={() => setispressed(false)}
            onClick={() => {
              if (checkBid()) return;
              setPlayBid(true);
              setUser({ ...user, balance: user.balance - bid });
            }}
            className="sell buy"
          >
            Buy
          </button>
        </div>
        <div style={{ zIndex: 1 }}>
          <div className="btn-container">
            <button
              className="deposit withdraw"
            >
              Withdraw
            </button>
            <div className="your-bid">
              {playBid === null ? `Your bid: ${bid}` : "Bet is done"}
            </div>
            <button
              className="deposit"
            >
              Deposit
            </button>
          </div>
          <div
            style={{ marginTop: 20, justifyContent: "center", gap: 20 }}
            className="btn-container"
          >
            {availableBids.map((value, i) => {
              return (
                <div
                  key={i}
                  onClick={() => setIndexBid(i)}
                  className={`your-bid ${indexBid === i ? "active" : ""}`}
                >
                  {value}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {animatedItems.map(({elem}) => elem)}
    </PageWrapper>
  );
};

export default Home;
