import React, { useContext } from 'react'
import Icon from 'UI/Icon'
import PageWrapper from 'UI/PageWrapper'
import UserBalance from 'UI/UserBalance'
import appContext from 'utils/app-context'
import './Rating.css'
type Props = {}
const testphoto ='https://s3-alpha-sig.figma.com/img/3d7c/f43b/c9487ce306ef42f715f85d1729328a1a?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TEU9L~E1DVSnJomGraU8JP9mz-~XgjrbTZqtalhosqLr9UCduzkpvTczyDO18CfU5QOcrLgOutuRJNLBueLXD8f9hkoMN9htgwIyPl2nrpVbQPDsCiDhzBc2zCb9GKodJ0-yClt1uCsxAAV7CLUkYp7T64ydMg4Zv-LcAvvWjEqOSorfGRdBfLwAvUu0FOftxKxFvH~arysoKrmVrmAhoPbvW9VHetOt03J0UKjXfOxdxdgREvu1aITEfdGwQNZ59b15j7wI48~o1XL2WjU01LEsUTHyBpFE8PkkM9UAfdLz7zMYxEienAn1BmFHMZBgXVhNedkUqEDafMOktBs6xg__'
const Rating = (props: Props) => {
  const {user} = useContext(appContext)
  const current = 10
  return (
    <PageWrapper className='friends-page'>
      <UserBalance/>
      <h1>Rating</h1>
     
      <ul className='friends-list'>
        {Array.from({length:20}).map((_,i) => {
          const isMe = current === i
          return (
            <li className={isMe ? 'me': ''}>
              <div className='friends-profile'>
                <img src={isMe ? user?.avatar : testphoto} alt="" className='friend-photo'/>
                <span>{isMe ? user?.first_name :'@name'}</span>
              </div>
              <div>
                {i <= 2 && <Icon icon='navigationRating'/>}
                <span>{i+1}</span>
              </div>
            </li>
          )
        })}
      </ul>
    </PageWrapper>
  )
}

export default Rating